import { useQuery } from "@apollo/client";
import { Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import Container from "shared/components/Container";
import { AiOutlineDeploymentUnit, AiFillSetting } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import cuid from "cuid";
import Button from "shared/components/Button";
import Header from "shared/components/Header";
import Heading from "shared/components/Heading";
import { COLORS } from "shared/style/colors";
import graphql from "utils/api/graphql";
import { DATE_TIME_FORMAT, PRODUCT_TYPES, SCREENS } from "utils/constants";
import Loading from "shared/components/Loading";
import { NAME_SPACES } from "shared/locales/constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDrawers } from "shared/contexts/Drawers";
import Drawers from "./Drawers";

const ACTIONS = {
  [PRODUCT_TYPES.ORDINALS_LENDER.value]: (clicks) => [],
  [PRODUCT_TYPES.BTC_LENDER.value]: (clicks) => [
    <Button
      key={cuid()}
      mode={"secondary--light"}
      onClick={clicks.flowChart}
      icon={<AiFillSetting />}
    >
      Configure
    </Button>,
  ],
  [PRODUCT_TYPES.BTC_STAKER.value]: (clicks) => [],
  [PRODUCT_TYPES.BTC_AIRDROP.value]: (clicks) => [
    <Button
      key={cuid()}
      mode={"secondary--light"}
      onClick={clicks.activity}
      icon={<AiOutlineDeploymentUnit />}
    >
      Activity
    </Button>,
  ],
  [PRODUCT_TYPES.ORDINALS_STAKER.value]: (clicks) => [],
  [PRODUCT_TYPES.ORDINALS_OFFERS.value]: (clicks) => [],
  [PRODUCT_TYPES.LOCKER.value]: (clicks) => [],
  [PRODUCT_TYPES.AUCTION.value]: (clicks) => [],
  [PRODUCT_TYPES.VESTING.value]: (clicks) => [],
};

function List() {
  const { drawers } = useDrawers();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.PRODUCT);
  const LIST = t("LIST", { returnObjects: true });
  const { data, loading, error } = useQuery(graphql.queries.PRODUCTS, {
    variables: { where: {} },
  });
  const [open, setOpen] = useState({ right: false, left: false });
  const [enabled, setEnabled] = useState({
    configure: false,
  });
  const navigate = useNavigate();
  const columns = [
    {
      title: LIST.NAME,
      dataIndex: "name",
      key: "name",
      align: "left",
    },
    {
      title: LIST.TYPE,
      dataIndex: "type",
      align: "left",
      render: (_) =>
        Object.values(PRODUCT_TYPES).find((__) => __.value === _).label,
    },
    {
      title: LIST.KEY.VALUE,
      key: "key",
      align: "left",
      render: (_, record) => {
        return (
          <Tag bordered={false} color="geekblue">{`${
            LIST.KEY.RENDERERS.CREATED_AT
          } ${moment
            .unix(parseInt(record.key.createdDate / 1000))
            .format(DATE_TIME_FORMAT)}`}</Tag>
        );
      },
    },
    {
      title: LIST.KEY.STATUS,
      key: "status",
      dataIndex: "status",
      align: "center",
      render: (_) => <Tag color={COLORS.C_GREEN}>{"Active"}</Tag>,
    },
    {
      title: LIST.ACTIONS.TITLE,
      key: "action",
      align: "right",
      render: (_) => {
        return (
          <div className="d-flex justify--end">
            {ACTIONS[_.type]({
              activity: () =>
                navigate(SCREENS.PRIVATE.ORGANIZATION.ACTIVITY.TRACE.path),
              flowChart: () => {
                setEnabled((_) => {
                  return {
                    configure: true,
                  };
                });
                setOpen((_) => {
                  return { ..._, right: true };
                });
              },
            }).map((x) => x)}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const displayDrawer = () => {
      drawers.setItems((_) =>
        Drawers({
          open,
          enabled,
          onClose: () => {
            drawers.setItems((_) => []);
            setOpen((_) => ({ ..._, right: false, left: false }));
          },
        }).filter((_) => _.enabled)
      );
    };
    displayDrawer();
  }, [open, enabled]);

  if (error || loading) return <Loading />;

  const {
    products: { data: products },
  } = data;

  return (
    <Container>
      <div className="section--row">
        <Header
          key={cuid()}
          title={<Heading title={LIST.HEADER.TITLE} />}
          subtitle={LIST.HEADER.SUBTITLE}
          actions={[
            {
              text: LIST.HEADER.NEW,
              onClick: () =>
                navigate(
                  SCREENS.PRIVATE.ORGANIZATION.PRODUCTS.CREATE.PROCESS.path
                ),
            },
          ]}
        />
      </div>
      <Table
        className="custom__table m-top-20"
        scroll={{ x: 400 }}
        columns={columns}
        dataSource={products}
        rowKey={"id"}
        size="small"
      />
    </Container>
  );
}

export default List;
