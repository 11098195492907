export const COLORS = {
  C_MAIN: "#0e102e",
  C_SECOND: "#f7931a",
  C_THIRD: "#201F57",
  C_FORTH: "#8fa2f7",
  C_DARK: "#0D1539",
  c_DARK_2: "#131938",
  C_BLACK: "#070C24",
  C_WHITE: "#ffffff",
  C_GRAY: "#8A92A5",
  C_GRAY_L2: "#FCFCFC",
  C_BLUE_LIGHT: "#28375A",
  C_BLUE_DARK: "#002E74",
  C_GREEN: "#40E3BE",
  C_RED: "#FF5A93",
  C_GOLD: "#D4A017",
  C_TEXT: "#565e86",
};
