import React, { useCallback } from "react";
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  Position,
  MarkerType,
  addEdge,
  Background,
  BackgroundVariant,
} from "reactflow";

import "reactflow/dist/style.css";
import "./custom.css";

import Node from "./Node";

const nodeTypes = {
  custom: Node,
};

const initialNodes = [
  {
    id: "01",
    position: { x: 300, y: 0 },
    data: {
      label: "Button 1",
    },
  },
  {
    id: "02",
    position: { x: 400, y: 0 },
    data: {
      label: "Button 2",
    },
  },
  {
    id: "03",
    position: { x: 500, y: 0 },
    data: {
      label: "Button 3",
    },
  },
  {
    id: "1",
    position: { x: 0, y: 0 },
    type: "custom",
    data: {
      type: "input",
      title: "Source 1",
      label: <small>{"Deep Lake fetches {amount} from borrower Wallet"}</small>,
    },
    sourcePosition: Position.Right,
  },
  {
    id: "2",
    position: { x: 0, y: 200 },
    type: "custom",
    data: {
      type: "input",
      title: "Source 2",
      label: (
        <small>
          {
            "Deep Lake estimates and fetches {fees} for the network from borrower Wallet"
          }
        </small>
      ),
    },
    sourcePosition: Position.Right,
  },
  {
    id: "3",
    position: { x: 400, y: 100 },
    type: "custom",
    data: {
      type: "lock",
      title: "Locked Vault",
      label: (
        <div>
          <small>
            This is a time lock Bitcoin Transaction, that has two spending path
            (click on the plus button to add more paths)
          </small>
          <ul>
            <li>
              <small>{"Offchain TX: {TXID #1}"}</small>
            </li>
            <li>
              <small>{"Offchain TX: {TXID #1}"}</small>
            </li>
          </ul>
        </div>
      ),
    },
    targetPosition: Position.Left,
  },
  {
    id: "4",
    position: { x: 400, y: 300 },
    data: { label: <span>{"Broadcasted TX with ID {TXID #1}"}</span> },
    targetPosition: Position.Top,
  },
  {
    id: "5",
    position: { x: 0, y: 500 },
    type: "custom",
    data: {
      type: "input",
      title: "Source 1",
      label: (
        <small>
          {"Deep Lake uses previous Onchain Broadcasted Vault as Input"}
        </small>
      ),
    },
    sourcePosition: Position.Right,
  },
  {
    id: "6",
    position: { x: 0, y: 700 },
    data: { label: <span>{"Fee: {YOUR_FEES}"}</span> },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
  {
    id: "7",
    position: { x: 400, y: 600 },
    data: { label: <span>{"Offchain TX: {TXID #2}"}</span> },
    targetPosition: Position.Left,
  },
  {
    id: "8",
    position: { x: 400, y: 800 },
    data: { label: <span>{"Broadcasted TX with ID {TXID #2}"}</span> },
    targetPosition: Position.Top,
  },
];

const initialEdges = [
  {
    id: "e1-3",
    source: "1",
    target: "3",
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    label: "lock",
  },
  {
    id: "e2-3",
    source: "2",
    target: "3",
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  },
  {
    id: "e3-4",
    source: "3",
    target: "4",
    type: "smoothstep",
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    label: "broadcast-lock",
  },
  {
    id: "e4-5",
    source: "4",
    target: "5",
    type: "smoothstep",
    markerStart: {
      type: MarkerType.ArrowClosed,
    },
    label: "spends from previously broadcasted vault",
  },
  {
    id: "e5-7",
    source: "5",
    target: "7",
    animated: true,
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    label: "unlock",
  },
  {
    id: "e6-7",
    source: "6",
    target: "7",
    animated: true,
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
  },
  {
    id: "e7-8",
    source: "7",
    target: "8",
    animated: true,
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    label: "broadcast-unlock",
  },
];

const getLayoutedElements = (nodes, edges) => {
  return { nodes, edges };
};

const LayoutFlow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  // we are using a bit of a shortcut here to adjust the edge type
  // this could also be done with a custom edge for example
  const edgesWithUpdatedTypes = edges.map((edge) => {
    if (edge.sourceHandle) {
      const edgeType = nodes.find((node) => node.type === "custom").data
        .selects[edge.sourceHandle];
      edge.type = edgeType;
    }

    return edge;
  });

  const onLayout = useCallback(() => {
    const layouted = getLayoutedElements(nodes, edges);
    setNodes([...layouted.nodes]);
    setEdges([...layouted.edges]);
  }, [nodes, edges]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edgesWithUpdatedTypes}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
    >
      <Background
        id="1"
        style={{ maxHeight: "50%", backgroundColor: "yellow" }}
      />
      <Background
        id="2"
        style={{
          marginTop: "50%",
          maxHeight: "50%",
          backgroundColor: "magenta",
        }}
      />
    </ReactFlow>
  );
};

export default function () {
  return (
    <ReactFlowProvider>
      <LayoutFlow />
    </ReactFlowProvider>
  );
}
