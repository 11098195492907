import { Col, Row } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Container from "shared/components/Container";
import Form from "shared/components/Form";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Detail = ({ onSubmit, webhook, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.WEBHOOK);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const { name, description, url, organization } = webhook;
  return (
    <Container>
      <Formik
        initialValues={{
          name,
          description,
          url,
          organization: organization.id,
        }}
        onSubmit={onSubmit}
        validationSchema={validation(
          t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
        )}
      >
        {({ isValid, handleSubmit, ...formProps }) => [
          <Header
            key={"header"}
            title={DETAIL.HEADER.TITLE}
            subtitle={DETAIL.HEADER.ACTION}
            actions={[
              {
                text: DETAIL.HEADER.DISCARD,
                onClick: cancel,
                variant: "outlined",
              },
              {
                text: DETAIL.HEADER.SAVE,
                onClick: handleSubmit,
              },
            ]}
          />,
          <Row gutter={[32]}>
            <Col
              xs={{ span: 24, order: 1 }}
              sm={{ span: 24, order: 1 }}
              md={{ span: 20, offset: 2, order: 1 }}
              lg={{ span: 14, offset: 5, order: 2 }}
            >
              <div className="inputs--container">
                <div className="inputs--container__header">
                  <span>{FORM.GENERAL_INFO}</span>
                </div>
                <Form type={"vertical"}>
                  <div className="field--item">
                    <label>{FORM.NAME}</label>
                    <Input
                      placeholder={FORM.NAME}
                      {...bindInputProps({ name: "name", ...formProps })}
                    />
                  </div>
                  <div className="field--item">
                    <label>{FORM.DESCRIPTION}</label>
                    <Input
                      placeholder={FORM.DESCRIPTION}
                      {...bindInputProps({
                        name: "description",
                        ...formProps,
                      })}
                    />
                  </div>
                  <div className="field--item">
                    <label>{FORM.URL}</label>
                    <Input
                      placeholder={FORM.URL}
                      {...bindInputProps({
                        name: "url",
                        ...formProps,
                      })}
                    />
                  </div>
                </Form>
              </div>
            </Col>
          </Row>,
        ]}
      </Formik>
    </Container>
  );
};

export default Detail;
