import { useTranslation } from "react-i18next";
import Container from "shared/components/Container";
import Heading from "shared/components/Heading";
import Header from "shared/components/Header";
import { NAME_SPACES } from "shared/locales/constants";
import { useDrawers } from "shared/contexts/Drawers";
import { useEffect, useState } from "react";
import Drawers from "./Drawers";
import Drops from "./Drops";
import { useLocation } from "react-router-dom";

function View() {
  const { state } = useLocation();
  const [open, setOpen] = useState({
    right: false,
    left: false,
    distribution: false,
  });
  const [enabled, setEnabled] = useState({
    supply: false,
    stakers: false,
  });
  const { drawers } = useDrawers();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.ACTIVITY);
  const LIST = t("LIST", { returnObjects: true });

  useEffect(() => {
    const displayDrawer = () => {
      drawers.setItems((_) =>
        Drawers({
          open,
          enabled,
          onClose: () => {
            drawers.setItems((_) => []);
            setOpen((_) => ({ ..._, right: false, left: false }));
          },
        }).filter((_) => _.enabled)
      );
    };
    displayDrawer();
  }, [open, enabled]);

  return (
    <div className="organization__area">
      <Container>
        <Header
          key="header"
          title={<Heading title={LIST.HEADER.TITLE} />}
          subtitle={LIST.HEADER.SUBTITLE}
          actions={[
            {
              text: "Today Drop",
              onClick: () => {
                setEnabled((_) => {
                  return {
                    distribution: true,
                    supply: false,
                    stakers: false,
                  };
                });
                setOpen((_) => {
                  return { ..._, right: true };
                });
              },
            },
            {
              text: "Supply",
              onClick: () => {
                setEnabled((_) => {
                  return {
                    distribution: false,
                    supply: true,
                    stakers: false,
                  };
                });
                setOpen((_) => {
                  return { ..._, right: true };
                });
              },
            },
            {
              text: "Stakers",
              onClick: () => {
                setEnabled((_) => {
                  return {
                    distribution: false,
                    supply: false,
                    stakers: true,
                  };
                });
                setOpen((_) => {
                  return { ..._, right: true };
                });
              },
            },
          ]}
        />
        <Drops product={state.product} />
      </Container>
    </div>
  );
}

export default View;
