import { startCase } from "lodash";
import { AiOutlineMenu } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import logo from "shared/assets/images/logo.svg";

const Header = ({ showSider, actions, menu }) => {
  return (
    <header className="header__area">
      <div className="header__area--top">
        <div className="container row">
          <div className="header__area--left">
            <span onClick={showSider} className="toggle">
              <AiOutlineMenu size={24} color="#15357a" />
            </span>
            <img src={logo} alt="" />
          </div>
          <div className="header__area--right">
            <ul className="actions">
              {actions.map((Action, index) => (
                <li key={`header-action-${index}`}>
                  <Action />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="header__area--bottom">
        <div className="container row">
          <ul className="nav--menu">
            {menu.map((item) => (
              <li key={item.id}>
                <NavLink to={item.to} end>
                  {item.element}
                </NavLink>
              </li>
            ))}
          </ul>
          <div className="mode--action">
            <small>
              {startCase(process.env.REACT_APP_NETWORK.toLocaleLowerCase())}
            </small>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
