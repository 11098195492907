import React, { useState } from "react";
import { FiCopy } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "shared/components/Button";
import { SCREENS } from "utils/constants";
import { NAME_SPACES } from "shared/locales/constants";
import Container from "shared/components/Container";

const Key = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.PRODUCT);
  const [copied, setCopied] = useState(false);
  const CREATE = t("CREATE", { returnObjects: true });
  return (
    <Container>
      <div className={`modal__float show`}>
        <div className="form">
          <div className="head">
            <h3>{CREATE.KEY.TITLE}</h3>
            <span
              className="copy"
              onClick={() => {
                navigator.clipboard.writeText(state.key.value);
                return setCopied(true);
              }}
            >
              <span>{CREATE.KEY.ACTIONS.COPY}</span> <FiCopy />
            </span>
          </div>
          <code>{state.key.value}</code>
          <Button
            mode={"primary"}
            alignment={"right"}
            onClick={() => navigate(SCREENS.PRIVATE.ORGANIZATION.PRODUCTS.LIST)}
            disabled={!copied}
          >
            {CREATE.KEY.ACTIONS.DONE}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Key;
