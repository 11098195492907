import { Spin } from "antd";

const Loading = () => {
  return (
    <div className="loading__area">
      <Spin />
    </div>
  );
};

Loading.propTypes = {};

Loading.defaultProps = {};

export default Loading;
