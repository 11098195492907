import { Button } from "antd";
import PropTypes from "prop-types";

const ALIGNMENTS = ["left", "center", "right"];

const MODES = [
  "default",
  "primary",
  "secondary",
  "primary--light",
  "secondary--light",
  "primary--link",
  "secondary--link",
  "primary--outlined",
  "success",
  "success--link",
  "success--light",
  "gray",
  "gray--outlined",
  "warning",
  "warning--link",
  "warning--light",
  "danger",
  "danger--link",
  "danger--outlined",
  "danger--light",
];

const Component = ({
  mode,
  alignment,
  children,
  size,
  type,
  iconReverse,
  ...props
}) => (
  <div className={`button__wrapper ${alignment} `}>
    <Button
      className={[mode, size, iconReverse && "icon--reverse"]}
      size={size}
      type={type}
      {...props}
    >
      {children}
    </Button>
  </div>
);

Component.propTypes = {
  alignment: PropTypes.oneOf(ALIGNMENTS),
  mode: PropTypes.oneOf(MODES),
};

Component.defaultProps = {
  alignment: "left",
  mode: "default",
  size: "small",
};

export default Component;
