import { get, isEmpty } from "lodash";
import passwordValidator from "password-validator";
import { Col } from "antd";
import { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "shared/components/Button";
import Input from "shared/components/Input";
import Row from "shared/components/Row";
import { COLORS } from "shared/style/colors";
import { SCREENS } from "utils/constants";
import { useMutation, api } from "utils/api/rest";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";

const Access = () => {
  const { state: initialState } = useLocation();
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.AUTH);
  const [createOrganization] = useMutation(api.id.organizations.create, {
    onSuccess: () => {
      return navigate(SCREENS.AUTH.SIGNUP.CONFIRM_EMAIL.path, { state });
    },
    onError: () => {},
  });
  return (
    <div className="onboarding__area">
      <div className="onboarding__area--content">
        <div className="head">
          <span>{t("SIGNUP.ACCESS.ALREADY_HAVE_ACCOUNT")}</span>
          <Button
            mode={"secondary--light"}
            onClick={() => {
              return navigate(SCREENS.AUTH.SIGN_IN.path);
            }}
          >
            {t("SIGNUP.ACCESS.NAVIGATION.SIGNIN")}
          </Button>
        </div>
        <div className="form">
          <div
            className="back__arrow"
            onClick={() => navigate(SCREENS.AUTH.SIGNUP.PROFILE.path)}
          >
            <BsArrowLeft size={24} color={COLORS.C_BLACK} />
            <span>{t("SIGNUP.ACCESS.NAVIGATION.BACK")}</span>
          </div>
          <h2 className="title">{t("SIGNUP.ACCESS.TITLE")}</h2>
          <div className="feild--item">
            <div className="m-top-24 m-bottom-24 ">
              <Row>
                <Input
                  placeholder={t("SIGNUP.ACCESS.FIRST_NAME")}
                  onChange={(__) => {
                    return setState((_) => ({
                      ..._,
                      organization: {
                        ..._.organization,
                        users: [
                          {
                            ..._.organization.users[0],
                            firstname: __.target.value,
                          },
                        ],
                      },
                    }));
                  }}
                />
                <Input
                  placeholder={t("SIGNUP.ACCESS.LAST_NAME")}
                  onChange={(__) => {
                    return setState((_) => ({
                      ..._,
                      organization: {
                        ..._.organization,
                        users: [
                          {
                            ..._.organization.users[0],
                            lastname: __.target.value,
                          },
                        ],
                      },
                    }));
                  }}
                />
              </Row>
              <Col span={24}>
                <Input
                  placeholder={t("SIGNUP.ACCESS.ORGANIZATION_NAME")}
                  onChange={(__) => {
                    return setState((_) => ({
                      ..._,
                      organization: {
                        ..._.organization,
                        name: __.target.value,
                      },
                    }));
                  }}
                />
              </Col>
              <Col span={24}>
                <Input
                  placeholder={t("SIGNUP.ACCESS.WORK_EMAIL_ADDRESS")}
                  onChange={(__) => {
                    return setState((_) => ({
                      ..._,
                      organization: {
                        ..._.organization,
                        users: [
                          {
                            ..._.organization.users[0],
                            email: __.target.value,
                            username: __.target.value,
                          },
                        ],
                      },
                    }));
                  }}
                />
              </Col>
              <Col span={24}>
                <Input
                  type={"password"}
                  placeholder={t("SIGNUP.ACCESS.PASSWORD")}
                  onChange={(__) => {
                    const password = __.target.value;
                    const schema = new passwordValidator();
                    schema
                      .is()
                      .min(12)
                      .is()
                      .max(100)
                      .has()
                      .uppercase()
                      .has()
                      .lowercase()
                      .has()
                      .symbols()
                      .has()
                      .digits(1)
                      .has()
                      .not()
                      .spaces();
                    const isValid = schema.validate(password);
                    if (!isValid) return;
                    return setState((_) => ({
                      ..._,
                      organization: {
                        ..._.organization,
                        users: [
                          {
                            ..._.organization.users[0],
                            password,
                          },
                        ],
                      },
                    }));
                  }}
                />
                <small>{t("SIGNUP.ACCESS.PASSWORD_REQUIREMENT")}</small>
              </Col>
              {/*<Col span={24}>
                <div className="agreement">
                  <span>
                    {t("SIGNUP.ACCESS.TERMS")}{" "}
                    <a href="">{t("SIGNUP.ACCESS.NAVIGATION.TERMS")}</a>.
                  </span>
                  <Switch />
                </div>
                </Col>*/}
            </div>
            <Button
              mode={"primary"}
              size={"full--width"}
              alignment={"right"}
              disabled={
                isEmpty(get(state, "organization.users[0].email")) ||
                isEmpty(get(state, "organization.users[0].password"))
              }
              onClick={() => {
                return createOrganization(state.organization);
              }}
            >
              {t("SIGNUP.ACCESS.NAVIGATION.NEXT")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Access;
