import { Col, Row } from "antd";
import React, { memo } from "react";
import { Position, Handle } from "reactflow";
import { AiFillPlusCircle } from "react-icons/ai";
import Heading from "shared/components/Heading";
import Header from "shared/components/Header";
import cuid from "cuid";

const Input = ({ id, data }) => {
  return (
    <div style={{ padding: 5 }}>
      <Header title={<Heading title={data.title} />} actions={[]} />
      <Row>
        <Col>{data.label}</Col>
      </Row>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Right} id={id} />
    </div>
  );
};

const Lock = ({ id, data }) => {
  return (
    <div style={{ padding: 5 }}>
      <Header
        title={<Heading title={data.title} />}
        actions={[
          {
            text: <AiFillPlusCircle />,
            onClick: () => {},
          },
        ]}
      />
      <Row>
        <Col>{data.label}</Col>
      </Row>
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Bottom} id={id} />
    </div>
  );
};

const Default = ({ id, data }) => {
  return (
    <div style={{ padding: 5 }}>
      <Header
        title={<Heading title={data.title} />}
        actions={[
          {
            text: <AiFillPlusCircle />,
            onClick: () => {},
          },
        ]}
      />
      <Row>
        <Col>{data.label}</Col>
      </Row>
      <Handle type="source" position={Position.Right} id={cuid()} />
    </div>
  );
};

const Nodes = {
  input: Input,
  lock: Lock,
  default: Default,
};

function Node({ id, data }) {
  const { type = "default" } = data;
  const Widget = Nodes[type];
  return <Widget {...{ id, data }} />;
}

export default memo(Node);
