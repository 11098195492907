import React, { useState } from "react";
import { KEYS } from "utils/constants";

export const Context = React.createContext("store");
export const { Consumer } = Context;

export const Provider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem(KEYS.ACCESS_TOKEN)
  );
  const [user, setUser] = useState(JSON.parse(localStorage.getItem(KEYS.USER)));
  const clearStore = (cb = () => {}) => {
    localStorage.clear();
    setAccessToken(undefined);
    setUser(undefined);
    cb();
  };

  return (
    <Context.Provider
      value={{
        clearStore,
        setAccessToken: (data) => {
          setAccessToken(data.accessToken);
          return localStorage.setItem(KEYS.ACCESS_TOKEN, data.accessToken);
        },
        accessToken: accessToken === "undefined" ? undefined : accessToken,
        setUser: (data) => {
          setUser(data.user);
          return localStorage.setItem(KEYS.USER, JSON.stringify(data.user));
        },
        user,
      }}
    >
      {children}
    </Context.Provider>
  );
};
