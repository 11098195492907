import PropTypes from "prop-types";

const Label = ({ label, icon }) => {
  return (
    <div className={`${label ? "label__area" : "hide--label"}`}>
      <span className={`${icon ? "" : "hide--icon"}`}>{icon}</span>
      <label>{label}</label>
    </div>
  );
};

Label.propTypes = {
  label: PropTypes.oneOf(PropTypes.string, PropTypes.object),
  className: PropTypes.string,
  icon: PropTypes.node,
};

Label.defaultProps = {
  setType: () => {},
};

export default Label;
