import { gql } from "@apollo/client";

export const ORGANIZATIONS = gql`
  query Organizations($where: OrganizationWhereInput) {
    organizations(where: $where) {
      data {
        id
        name
        logo
        webhooks {
          id
          url
          name
          description
        }
      }
      count
    }
  }
`;

export const ORGANIZATION = gql`
  query Organization($where: OrganizationWhereInput) {
    organization(where: $where) {
      id
      name
      logo
      webhooks {
        url
        name
        description
      }
    }
  }
`;

const organization = { ORGANIZATIONS, ORGANIZATION };

export default organization;
