import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Loading";
import { SCREENS } from "utils/constants";
import graphql from "utils/api/graphql";
import { useMutation } from "@apollo/client";
import { StoreContext } from "shared/contexts/store";

const Live = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { clearStore } = useContext(StoreContext);
  const [activateOrganization] = useMutation(
    graphql.mutations.ACTIVATE_ORGANIZATION,
    {
      onCompleted: () => {
        return clearStore(() => navigate(SCREENS.AUTH.SIGN_IN.path));
      },
      onError: () => {},
    }
  );
  useEffect(() => {
    const setLive = () => {
      activateOrganization({ variables: { where: { id } } });
    };
    return setLive();
  }, [activateOrganization, id]);

  return <Loading />;
};

export default Live;
