import { SCREENS } from "utils/constants";
import Container from "shared/components/Container";

function Home() {
  return <Container>Coming Soon</Container>;
}

const routes = [
  {
    path: SCREENS.PRIVATE.ORGANIZATION.HOME,
    exact: true,
    element: <Home />,
  },
];

export default routes;
