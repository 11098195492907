import { useMutation, useQuery } from "@apollo/client";
import { Col, Row, Popover, Dropdown, Button, Switch } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import Bitcoin from "shared/assets/images/bitcoin.svg";
import Container from "shared/components/Container";
import Header from "shared/components/Header";
import Heading from "shared/components/Heading";
import Input from "shared/components/Input";
import Loading from "shared/components/Loading";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { LIMIT_TYPES, SCREENS } from "utils/constants";
import { useNavigate } from "react-router-dom";

function List() {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ORGANIZATION.LIMIT);
  const LIST = t("LIST", { returnObjects: true });
  const [updateLimit] = useMutation(graphql.mutations.UPDATE_LIMIT);
  const { data, loading, error } = useQuery(graphql.queries.LIMITS);
  const [createLimit] = useMutation(graphql.mutations.CREATE_LIMIT, {
    onCompleted: () => {
      return navigate(SCREENS.PRIVATE.ORGANIZATION.LIMITS.LIST);
    },
  });

  if (loading || error) return <Loading />;

  const limits = data.limits.data.reduce(
    (accumulator, current) => {
      accumulator[current.type] = true;
      return accumulator;
    },
    {
      [LIMIT_TYPES.MAX_AMOUNT_PER_ASSET]: false,
      [LIMIT_TYPES.MIN_AMOUNT_PER_ASSET]: false,
      [LIMIT_TYPES.TOTAL_AMOUNT_PER_COLLATERAL]: false,
      [LIMIT_TYPES.MAX_ASSETS_PER_COLLATERAL]: false,
      [LIMIT_TYPES.TICKERS]: false,
      [LIMIT_TYPES.COLLECTIONS]: false,
      [LIMIT_TYPES.INSCRIPTIONS]: false,
    }
  );

  const limitsItems = data.limits.data.reduce(
    (accumulator, current) => {
      const selectors = {
        [LIMIT_TYPES.MAX_AMOUNT_PER_ASSET]: (current) => ({
          value: get(current, "limitItems[0].value.amount"),
          id: current.id,
        }),
        [LIMIT_TYPES.MIN_AMOUNT_PER_ASSET]: (current) => ({
          value: get(current, "limitItems[0].value.amount"),
          id: current.id,
        }),
        [LIMIT_TYPES.TOTAL_AMOUNT_PER_COLLATERAL]: (current) => ({
          value: get(current, "limitItems[0].value.amount"),
          id: current.id,
        }),
        [LIMIT_TYPES.MAX_ASSETS_PER_COLLATERAL]: (current) => ({
          value: get(current, "limitItems[0].value.amount"),
          id: current.id,
        }),
        [LIMIT_TYPES.TICKERS]: (current) => ({
          value: get(current, "limitItems[0].value.indexes", []),
          id: current.id,
        }),
        [LIMIT_TYPES.COLLECTIONS]: (current) => ({
          value: get(current, "limitItems[0].value.indexes", []),
          id: current.id,
        }),
        [LIMIT_TYPES.INSCRIPTIONS]: (current) => ({
          value: get(current, "limitItems[0].value.indexes", []),
          id: current.id,
        }),
      };
      accumulator[current.type] = selectors[current.type](current);
      return accumulator;
    },
    {
      [LIMIT_TYPES.MAX_AMOUNT_PER_ASSET]: { value: null },
      [LIMIT_TYPES.MIN_AMOUNT_PER_ASSET]: { value: null },
      [LIMIT_TYPES.TOTAL_AMOUNT_PER_COLLATERAL]: { value: null },
      [LIMIT_TYPES.MAX_ASSETS_PER_COLLATERAL]: { value: null },
      [LIMIT_TYPES.TICKERS]: { value: null },
      [LIMIT_TYPES.COLLECTIONS]: { value: null },
      [LIMIT_TYPES.INSCRIPTIONS]: { value: null },
    }
  );

  return (
    <Container>
      <div className="section--row">
        <Row justify={"space-between"}>
          <Col>
            <Header title={<Heading title={LIST.HEADER.TITLE} />} />
          </Col>
          <Col>
            <Dropdown
              menu={{
                items: Object.entries(limits).map(
                  ([limit, enabled], index) => ({
                    key: `limit-${index}`,
                    label: (
                      <Row>
                        <Col span={18}>
                          <small>
                            {LIST.HEADER.ENABLE_LIMITS.ITEMS[limit]}
                          </small>
                        </Col>
                        <Col span={1}>:</Col>
                        <Col span={4}>
                          <Switch
                            checked={enabled}
                            onChange={() => {
                              if (enabled) return;
                              return createLimit({
                                variables: {
                                  data: {
                                    type: limit,
                                  },
                                },
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    ),
                  })
                ),
              }}
              placement="bottomLeft"
            >
              <Button>{LIST.HEADER.ENABLE_LIMITS.TITLE}</Button>
            </Dropdown>
          </Col>
        </Row>
      </div>
      <Row gutter={[16, 24]}>
        <Col xs={24} sm={24} md={12} lg={8}>
          <Input
            disabled={!limits[LIMIT_TYPES.MAX_AMOUNT_PER_ASSET]}
            defaultValue={limitsItems[LIMIT_TYPES.MAX_AMOUNT_PER_ASSET].value}
            label={
              <span>
                {LIST.BODY.ITEMS.MAX_AMOUNT_PER_ASSET.TITLE}
                <Popover
                  content={
                    <div>
                      <p>
                        {
                          LIST.BODY.ITEMS.MAX_AMOUNT_PER_ASSET.INFORMATION
                            .LINE_1
                        }
                      </p>
                      <p>
                        {
                          LIST.BODY.ITEMS.MAX_AMOUNT_PER_ASSET.INFORMATION
                            .LINE_2
                        }
                      </p>
                      <p>
                        {
                          LIST.BODY.ITEMS.MAX_AMOUNT_PER_ASSET.INFORMATION
                            .LINE_3
                        }
                      </p>
                    </div>
                  }
                  title="Information"
                >
                  {" "}
                  <InfoCircleTwoTone />
                </Popover>
              </span>
            }
            suffix={
              <div className="d-flex">
                <img src={Bitcoin} alt="bitcoin-logo" /> BTC
              </div>
            }
            onChange={(event) => {
              return updateLimit({
                variables: {
                  where: {
                    id: limitsItems[LIMIT_TYPES.MAX_AMOUNT_PER_ASSET].id,
                  },
                  data: {
                    limitItems: [{ value: { amount: event.target.value } }],
                  },
                },
              });
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <Input
            disabled={!limits[LIMIT_TYPES.MIN_AMOUNT_PER_ASSET]}
            defaultValue={limitsItems[LIMIT_TYPES.MIN_AMOUNT_PER_ASSET].value}
            label={
              <span>
                {LIST.BODY.ITEMS.MIN_AMOUNT_PER_ASSET.TITLE}
                <Popover
                  content={
                    <div>
                      <p>
                        {
                          LIST.BODY.ITEMS.MAX_AMOUNT_PER_ASSET.INFORMATION
                            .LINE_1
                        }
                      </p>
                      <p>
                        {
                          LIST.BODY.ITEMS.MAX_AMOUNT_PER_ASSET.INFORMATION
                            .LINE_2
                        }
                      </p>
                      <p>
                        {
                          LIST.BODY.ITEMS.MAX_AMOUNT_PER_ASSET.INFORMATION
                            .LINE_3
                        }
                      </p>
                    </div>
                  }
                  title="Information"
                >
                  {" "}
                  <InfoCircleTwoTone />
                </Popover>
              </span>
            }
            suffix={
              <div className="d-flex">
                <img src={Bitcoin} alt="bitcoin-logo" /> BTC
              </div>
            }
            onChange={(event) => {
              return updateLimit({
                variables: {
                  where: {
                    id: limitsItems[LIMIT_TYPES.MIN_AMOUNT_PER_ASSET].id,
                  },
                  data: {
                    limitItems: [{ value: { amount: event.target.value } }],
                  },
                },
              });
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default List;
